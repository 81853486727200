import { send, upload } from "../index";

export function getStoreList(data, opts = {}) {
	//  商城列表
  return send({
    url: "/admin/store/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getStoreDetail(data, opts = {}) {
	//  商城詳情
  return send({
    url: "/admin/store/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveStore(data, opts = {}) {
	//  新增修改商城
  return send({
    url: "/admin/store/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteStore(data, opts = {}) {
	//  删除商城
  return send({
    url: "/admin/store/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsClassifyList(data, opts = {}) {
	//  商品分类列表
  return send({
    url: "/admin/goodsClassify/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsAllClassifyList(data, opts = {}) {
	//  全部商品分类列表
  return send({
    url: "/admin/goodsClassify/findAll.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsClassifyDetail(data, opts = {}) {
	//  商品分类详情
  return send({
    url: "/admin/goodsClassify/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveGoodsClassify(data, opts = {}) {
	//  商品分类添加
  return send({
    url: "/admin/goodsClassify/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateGoodsClassify(data, opts = {}) {
	//  商品分类修改
  return send({
    url: "/admin/goodsClassify/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteGoodsClassify(data, opts = {}) {
	//  商品分类删除
  return send({
    url: "/admin/goodsClassify/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsBrandList(data, opts = {}) {
	//  商品品牌列表
  return send({
    url: "/admin/goodsBrand/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsBrandDetail(data, opts = {}) {
	//  商品品牌详情
  return send({
    url: "/admin/goodsBrand/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveGoodsBrand(data, opts = {}) {
	//  商品品牌新增
  return send({
    url: "/admin/goodsBrand/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateGoodsBrand(data, opts = {}) {
	//  商品品牌修改
  return send({
    url: "/admin/goodsBrand/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesGoodsBrand(data, opts = {}) {
	//  商品品牌上架
  return send({
    url: "/admin/goodsBrand/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function pullOffShelvesGoodsBrand(data, opts = {}) {
	//  商品品牌下架
  return send({
    url: "/admin/goodsBrand/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteGoodsBrand(data, opts = {}) {
	//  商品品牌删除
  return send({
    url: "/admin/goodsBrand/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchPullOnShelvesGoodsBrand(data, opts = {}) {
	//  商品品牌批量启用
  return send({
    url: "/admin/goodsBrand/batchPullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchPullOffShelvesGoodsBrand(data, opts = {}) {
	//  商品品牌批量禁用
  return send({
    url: "/admin/goodsBrand/batchPullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchDeleteGoodsBrand(data, opts = {}) {
	//  商品品牌批量删除
  return send({
    url: "/admin/goodsBrand/batchDelete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderBaseList(data, opts = {}) {
	//  订单列表
  return send({
    url: "/admin/orderBase/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function verificationOrderBase(data, opts = {}) {
	//  核销订单
  return send({
    url: "/admin/orderBase/verification.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function sendOrderBase(data, opts = {}) {
	//  订单发货
  return send({
    url: "/admin/orderBase/send.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function importOrderBase(data, file) {
	//  导入订单
  return upload({
    url: "/admin/orderBase/import.do",
    method: "POST",
    data,
    file: file,
  });
}

export function orderAfterSale(data, opts = {}) {
	//  订单退款
  return send({
    url: "/admin/orderAfterSale/save.do",
   method: "POST",
   data,
   ...opts,
  });
}


export function getFeeSettingDetail(data, opts = {}) {
  return send({
    url: "/admin/postFeeSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateFeeSetting(data, opts = {}) {
  return send({
    url: "/admin/postFeeSetting/update.do",
	method: "POST",
	data,
	...opts,
  });
}

export function snackOrderAfterSale(data, opts = {}) {
  return send({
    url: "/admin/orderAfterSale/snackRefund.do",
	method: "POST",
	data,
	...opts,
  });
}