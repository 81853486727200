import { send } from "../index";

export function getGoodsList(data, opts = {}) {
	//  模板商品列表
  return send({
    url: "/admin/goods/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveGoods(data, opts = {}) {
	//  模板商品新增
  return send({
    url: "/admin/goods/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateGoods(data, opts = {}) {
	//  模板商品修改
  return send({
    url: "/admin/goods/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsDetail(data, opts = {}) {
	//  模板商品详情
  return send({
    url: "/admin/goods/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteGoods(data, opts = {}) {
	//  模板商品删除
  return send({
    url: "/admin/goods/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchDeleteGoods(data, opts = {}) {
	//  模板商品批量删除
  return send({
    url: "/admin/goods/batchDelete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesGoods(data, opts = {}) {
	//  模板商品上架
  return send({
    url: "/admin/goods/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesGoods(data, opts = {}) {
	//  模板商品下架
  return send({
    url: "/admin/goods/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getStoreGoodsList(data, opts = {}) {
	//  商城商品列表
  return send({
    url: "/admin/storeGoodsStock/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getStoreGoodsDetail(data, opts = {}) {
	//  商城商品详情
  return send({
    url: "/admin/storeGoodsStock/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveStoreGoods(data, opts = {}) {
	//  商城商品 新增
  return send({
    url: "/admin/storeGoodsStock/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateStoreGoods(data, opts = {}) {
	//  商城商品 修改
  return send({
    url: "/admin/storeGoodsStock/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function storeGoodsDelete(data, opts = {}) {
	//  商城商品删除
  return send({
    url: "/admin/storeGoodsStock/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesStoreGoods(data, opts = {}) {
	//  商城商品上架
  return send({
    url: "/admin/storeGoodsStock/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesStoreGoods(data, opts = {}) {
	//  商城商品下架
  return send({
    url: "/admin/storeGoodsStock/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchPullOnShelvesStoreGoods(data, opts = {}) {
	//  商城商品批量上架
  return send({
    url: "/admin/storeGoodsStock/batchPullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchPullOffShelvesStoreGoods(data, opts = {}) {
	//  商城商品批量下架
  return send({
    url: "/admin/storeGoodsStock/batchPullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function changeIsShow(data, opts = {}) {
	//  是否显示切换
  return send({
    url: "/admin/storeGoodsStock/changeIsShow.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function shareGoodsQR(data, opts = {}) {
	//  推广
  return send({
    url: "/admin/storeGoodsStock/shareQR.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveGoodsWithoutBaseGoods(data, opts = {}) {
  //  商城商品 新增 - 无商品库版本
  return send({
    url: "/admin/storeGoodsStock/saveGoods.do",
    method: "POST",
    data,
    ...opts,
  });
}
